import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-product-stat';

class StockProductStat {
    /** 상품 입고현황 조회 */
    async getProductWrhousngList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/wrhousng`, {params});
        return data;
    }
    /** 상품 출고현황 조회 */
    async getProductDlivyList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy`, {params});
        return data;
    }
    /** 상품 입출고 현황 조회 */
    async getProductStockHistory(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/history`, {params});
        return data;
    }
    /** 상품 재고 조회 */
    async getProductStockList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/stock`, {params});
        return data;
    }
    /** 상품 거래처별 입고현황 조회 */
    async getProductBcncWrhousngList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/bcnc-wrhousng`, {params});
        return data;
    }
    /** 상품 거래처별 일별 입고현황 조회 */
    async getProductBcncDailyWrhousngList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/bcnc-daily-wrhousng`, {params});
        return data;
    }


    /** 상품 재고 월마감-목록조회 */
    async getProductClosingList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/list`, {params});
        return data;
    }
    /** 상품 재고 월마감-상세조회 */
    async getProductClosingDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/detail`, {params});
        return data;
    }
    /** 상품 재고 월마감-상세저장(마감확정) */
    async postProductClosingDetail(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/closing/detail`, params);
        return data;
    }
    /** 상품 재고 월마감-상세삭제(마감해제) */
    async deleteProductClosingDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/closing/detail`, {params});
        return data;
    }


    /** 상품 월별 수불부 */
    async getProductMonthlyInvntryList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/monthly`, {params});
        return data;
    }

    /** 상품 월별 재고 실사 등록-목록조회 */
    async getProductStockMonthlyWrhousList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/stock-monthly-wrhous/list`, {params});
        return data;
    }
    /** 상품 월별 재고 실사 등록-상세조회 */
    async getProductStockMonthlyWrhousDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/stock-monthly-wrhous/detail`, {params});
        return data;
    }
    /** 상품 월별 재고 실사 등록-상세저장 */
    async postProductStockMonthlyWrhousDetail(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/stock-monthly-wrhous/detail`, params);
        return data;
    }
    /** 상품 월별 재고 실사 등록-상세삭제 */
    async deleteProductStockMonthlyWrhousDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/stock-monthly-wrhous/detail`, {params});
        return data;
    }

}
export default new StockProductStat();